<template>
    <modal ref="modalErrorPass" titulo="!Ups¡ Algo ha salido mal" no-aceptar no-cancelar>
        <div class="row mx-0 my-3 j-center">
            <div class="col-9 mt-3 text-general text-center f-300 f-17">
                Las contraseñas no coinciden 
            </div>
            <div class="col-9 mt-4">
                <div class="btn-general br-10" style="height:44px;">
                    Ingresar nuevamente
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    methods: {
        toggle(){
            this.$refs.modalErrorPass.toggle();
        }
    }
}
</script>